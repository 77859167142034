<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Interactice notification')"
          text-class="text-h6"
        />

        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />

        <q-space />

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          no-caps
          unelevated
          @click="refreshItems"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
          :is-open="isOpenFilter"
          :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              states: states
            }
          }"
          @submit="handleFiltersSubmit"
          @close="openCloseFilters"
          :has-groups="false"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
          style="height: calc(100vh - 130px);"
          class="sticky-header-table"
          row-key="id"
          :rows-per-page-label="$t('Rows per page')"
          :rows="interactions"
          :columns="columns"
          v-model:pagination="pagination"
          :loading="interactionsLoading"
          :filter="filter"
          virtual-scroll
          binary-state-sort
          flat
          @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
              showing
              color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
              :props="props"
              class="clickable"
              @dblclick="openInteraction(props.row)"
            >
              <q-td
                key="id"
                :props="props"
                class="text-subtitle1"
              >
                <strong>{{ props.row.id }}</strong>

                <br>

                {{ props.row.reason }}
              </q-td>

              <q-td
                key="state"
                :props="props"
              >
                <q-badge
                  :color="stateColors[props.row.state]"
                  text-color="dark"
                  class="q-pa-sm"
                >
                  {{ $t(props.row.state) }}
                </q-badge>
              </q-td>

              <q-td
                key="operator"
                :props="props"
              >
                <span v-if="hasValue(props.row.eav['integrations-asterisk-call'], 'oper')">
                  {{ props.row.eav['integrations-asterisk-call'].oper }}
                </span>

                <span v-else> - </span>
              </q-td>

              <q-td
                key="disposition"
                :props="props"
              >
                <span v-if="hasValue(props.row.eav['integrations-asterisk-call'], 'disposition')">
                  <span :class="'rounded q-px-sm q-py-xs text-white bg-' + callColors[props.row.eav['integrations-asterisk-call'].disposition]">
                    {{ $t(props.row.eav['integrations-asterisk-call'].disposition) }}
                  </span>
                </span>

                <span v-else> - </span>
              </q-td>

              <q-td
                key="order"
                :props="props"
              >
                <span v-if="props.row._embedded && props.row._embedded.order">
                  {{ props.row._embedded.order.id }}
                </span>
              </q-td>

              <q-td
                key="deliveryRequest"
                :props="props"
              >
                <span v-if="props.row._embedded && props.row._embedded.deliveryRequest">
                  {{ props.row._embedded.deliveryRequest.id }}
                </span>
              </q-td>

              <q-td
                key="reason"
                :props="props"
              >
                <span v-if="props.row.reason">
                  {{ props.row.reason }}
                </span>

                <span v-else> - </span>
              </q-td>

              <q-td
                key="created"
                :props="props"
              >
                {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="updated"
                :props="props"
              >
                {{ $moment(props.row.updated.date).format(appOptions.formats.date) }}
              </q-td>

              <q-td
                key="duration"
                :props="props"
              >
                <span v-if="hasValue(props.row.eav['integrations-asterisk-call'], 'cdrdur')">
                  {{ props.row.eav['integrations-asterisk-call'].cdrdur }}
                </span>

                <span v-else> - </span>
              </q-td>

              <q-td
                key="file"
                :props="props"
              >
                <a
                  v-if="hasAudio(props.row.eav.files)"
                  :href="props.row.eav.files.audio"
                  target="_blank"
                >
                  {{ $t('Download') }}
                </a>

                <span v-else> - </span>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>

    <q-dialog v-model="isOpenHistoryModal">
      <q-card style="min-width: 70vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('History') }}
          </div>

          <q-space />

          <q-btn
            v-close-popup
            icon="close"
            flat
            round
            dense
          />
        </q-card-section>

        <q-card-section>
          <history
            v-if="isOpenHistoryModal"
            :id="interaction.id"
            :entity="'Orderadmin\\Notifications\\Entity\\Crm\\Interaction'"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Components
import History from '../../components/history/history'
import Search from '../../components/search/Search.vue'
import FiltersCollapse from './../../components/filters/FilterCollapse'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'CallCenter',
  components: {
    FiltersCollapse,
    History,
    Search
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      callColors: {
        ANSWERED: 'success',
        REFUSED: 'danger'
      },
      stateColors: {
        new: 'yellow',
        delayed: 'danger',
        complete: 'success',
        cancelled: 'dark',
        deleted: 'default'
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Operator'),
          name: 'operator',
          align: 'left'
        },
        {
          label: this.$t('Disposition'),
          name: 'disposition',
          align: 'left'
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'left'
        },
        {
          label: this.$t('Shipping Request'),
          name: 'deliveryRequest',
          align: 'left'
        },
        {
          label: this.$t('Reason'),
          name: 'reason',
          align: 'left'
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left'
        },
        {
          label: this.$t('Updated'),
          name: 'updated',
          align: 'left'
        },
        {
          label: this.$t('Duration'),
          name: 'duration',
          align: 'left'
        },
        {
          label: this.$t('Download file'),
          name: 'file',
          align: 'left'
        }
      ],
      isOpenHistoryModal: false,
      interaction: null,
      activatedFields: [
        'id',
        'created.from',
        'created.to',
        'state',
        'shop',
        'order',
        'deliveryRequest'
      ],
      isOpenFilter: false,
      states: [
        { id: 'new', title: 'new' },
        { id: 'delayed', title: 'delayed' },
        { id: 'complete', title: 'complete' },
        { id: 'cancelled', title: 'cancelled' },
        { id: 'deleted', title: 'deleted' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'interactions',
      'totalInteractionsNumber',
      'interactionsLoading',
      'interactionsPage',
      'appOptions'
    ]),
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ') + this.totalInteractionsNumber
        : this.$t('Filter')
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  methods: {
    ...mapActions([
      'loadInteractions'
    ]),
    ...mapMutations([
      'setDeliveryRequest',
      'addErrorNotification'
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    loadDefaultItems () {
      return this.onRequest({})
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      this.updateParams(query)

      return this.loadInteractions(this.serverParams)
        .then(({ page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
        })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    openCloseFilters () {
      this.isOpenFilter = !this.isOpenFilter
    },
    openInteraction (row) {
      this.interaction = row
      this.isOpenHistoryModal = true
    },
    hasValue (value, key) {
      return value && value[key]
    },
    hasAudio (row) {
      return row && row.audio
    }
  }
}
</script>
